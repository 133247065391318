<template>
<div>

    <b-row>
        <!-- <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL BANCO DE NORMAS
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-tags fa-3x"></i> <br>
                            <span class="lg-numero">3</span>
                            <br>
                            <span class="text-muted">Etiquetas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col> -->
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Riesgos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block :to="{name: 'Evaluación de riesgos'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('003-RIE-EVA-RIE','c') == 1" cols="6" class="my-2">
                            <b-button  block size="md" variant="custom" @click="modalNuevoRiesgo = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo riesgo
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Riesgos registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                       <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaRiesgos" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('003-RIE-EVA-RIE','u') == 1"  @click="abrirModalVerDetalleRiesgo(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                       <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('003-RIE-EVA-RIE','d') == 1"  @click="eliminarRiesgo(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalNuevoRiesgo">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo riesgo </span>
            </h6>
            <CButtonClose @click="modalNuevoRiesgo = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevoRiesgo)">
                <b-row>
                    <b-col md="9">
                        <b-row>
                            <b-col md="6">
                                <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Nombre:" class="mb-2">
                                        <b-form-input v-model="datosRiesgo.nombre" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="proceso" rules="required" v-slot="{errors}">
                                    <b-form-group label="Proceso:" class="mb-2">
                                        <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRiesgo.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRiesgo.idProceso" :options="comboProcesos" @search:blur="blurProceso">
                                            <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                        </v-select>
                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="actividad" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Actividad:" class="mb-2">
                                        <b-form-input v-model="datosRiesgo.actividad" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese la actividad"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="tipo" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Tipo:" class="mb-2">
                                        <b-form-select v-model="datosRiesgo.tipo" :options="comboTipo" :state="getValidationState(validationContext)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="nivel" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Nivel:" class="mb-2">
                                        <b-form-select v-model="datosRiesgo.nivel" :options="comboNivel" :state="getValidationState(validationContext)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="3">
                        <b-row>
                            <b-col md="12">
                                <validation-provider name="gravedad" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Gravedad:" class="mb-2">
                                        <b-form-input v-model="datosRiesgo.gravedad" v-on:change="calcularNrt()" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese la gravedad"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="12">
                                <validation-provider name="detectabilidad" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Detectabilidad:" class="mb-2">
                                        <b-form-input v-model="datosRiesgo.detectabilidad" v-on:change="calcularNrt()" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese la detectabilidad"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="12">
                                <validation-provider name="ocurrencia" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Ocurrencia:" class="mb-2">
                                        <b-form-input v-model="datosRiesgo.ocurrencia" v-on:change="calcularNrt()" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese la ocurrencia"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="12">
                                <validation-provider name="nivel de tolerancia (NRT)" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Nivel de tolerancia (NRT):" class="mb-2">
                                        <b-form-input class="disabled-white" v-model="datosRiesgo.nivelTolerancia" disabled type="number" step=".01" :state="getValidationState(validationContext)"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="descripción" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosRiesgo.descripcion" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="modo de fallo" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Modo de fallo:" class="mb-2">
                                <b-form-textarea v-model="datosRiesgo.modoFallo" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el modo de fallo"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="efecto" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Efecto:" class="mb-2">
                                <b-form-textarea v-model="datosRiesgo.efecto" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el efecto"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="causa" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Causa:" class="mb-2">
                                <b-form-textarea v-model="datosRiesgo.causa" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la causa"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="control actual" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Control actual:" class="mb-2">
                                <b-form-textarea v-model="datosRiesgo.controlActual" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el control actual"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalNuevoRiesgo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalVerDetalleRiesgo">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Detalle del riesgo </span>
            </h6>
            <CButtonClose @click="modalVerDetalleRiesgo = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(verDetalleRiesgo)">
                <b-row>
                    <b-col md="9">
                        <b-row>
                            <b-col md="6">
                                <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Nombre:" class="mb-2">
                                        <b-form-input v-model="datosVerDetalleRiesgo.nombre" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="proceso" rules="required" v-slot="{errors}">
                                    <b-form-group label="Proceso:" class="mb-2">
                                        <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosVerDetalleRiesgo.idProceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosVerDetalleRiesgo.idProceso" :options="comboProcesos" @search:blur="blurModificarProceso">
                                            <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                        </v-select>
                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="actividad" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Actividad:" class="mb-2">
                                        <b-form-input v-model="datosVerDetalleRiesgo.actividad" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese la actividad"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="tipo" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Tipo:" class="mb-2">
                                        <b-form-select v-model="datosVerDetalleRiesgo.tipo" :options="comboTipo" :state="getValidationState(validationContext)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="6">
                                <validation-provider name="nivel" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Nivel:" class="mb-2">
                                        <b-form-select v-model="datosVerDetalleRiesgo.nivel" :options="comboNivel" :state="getValidationState(validationContext)">
                                            <template v-slot:first>
                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="3">
                        <b-row>
                            <b-col md="12">
                                <validation-provider name="gravedad" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Gravedad:" class="mb-2">
                                        <b-form-input v-model="datosVerDetalleRiesgo.gravedad" v-on:change="calcularNrtActualizar()" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese la gravedad"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="12">
                                <validation-provider name="detectabilidad" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Detectabilidad:" class="mb-2">
                                        <b-form-input v-model="datosVerDetalleRiesgo.detectabilidad" v-on:change="calcularNrtActualizar()" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese la detectabilidad"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="12">
                                <validation-provider name="ocurrencia" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Ocurrencia:" class="mb-2">
                                        <b-form-input v-model="datosVerDetalleRiesgo.ocurrencia" v-on:change="calcularNrtActualizar()" type="number" step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese la ocurrencia"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col md="12">
                                <validation-provider name="nivel de tolerancia (NRT)" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Nivel de tolerancia (NRT):" class="mb-2">
                                        <b-form-input class="disabled-white" v-model="datosVerDetalleRiesgo.nivelTolerancia" disabled type="number" step=".01" :state="getValidationState(validationContext)"></b-form-input>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="descripción" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosVerDetalleRiesgo.descripcion" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="modo de fallo" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Modo de fallo:" class="mb-2">
                                <b-form-textarea v-model="datosVerDetalleRiesgo.modoFallo" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el modo de fallo"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="efecto" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Efecto:" class="mb-2">
                                <b-form-textarea v-model="datosVerDetalleRiesgo.efecto" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el efecto"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="causa" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Causa:" class="mb-2">
                                <b-form-textarea v-model="datosVerDetalleRiesgo.causa" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la causa"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="control actual" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Control actual:" class="mb-2">
                                <b-form-textarea v-model="datosVerDetalleRiesgo.controlActual" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el control actual"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalVerDetalleRiesgo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalNuevoRiesgo: false,
            modalVerDetalleRiesgo: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "riesgo",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaRiesgos: [],
            comboProcesos: [],
            datosRiesgo: {
                nombre: '',
                idProceso: null,
                actividad: '',
                tipo: null,
                nivel: null,
                gravedad: '',
                detectabilidad: '',
                ocurrencia: '',
                nivelTolerancia: '',
                descripcion: '',
                modoFallo: '',
                efecto: '',
                causa: '',
                controlActual: '',
            },
            datosVerDetalleRiesgo: {
                idRiesgo: '',
                nombre: '',
                idProceso: null,
                actividad: '',
                tipo: null,
                nivel: null,
                gravedad: '',
                detectabilidad: '',
                ocurrencia: '',
                nivelTolerancia: '',
                descripcion: '',
                modoFallo: '',
                efecto: '',
                causa: '',
                controlActual: '',
            },
            comboTipo: [{
                    value: 1,
                    text: 'Riesgo'
                },
                {
                    value: 2,
                    text: 'Oportunidad'
                }
            ],
            comboNivel: [{
                    value: 1,
                    text: 'Estratégico'
                },
                {
                    value: 2,
                    text: 'Proceso'
                },
                {
                    value: 2,
                    text: 'Sistema de gestión'
                }
            ],
            disabled: false,
            datosSession: {
                idCliente: ''
            },
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },
        blurModificarProceso() {
            this.computedForm2.refs.proceso.validate();
        },
        listarRiesgos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-riesgos", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.listaRiesgos = response.data
                    me.totalRows = me.listaRiesgos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProcesos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        nuevoRiesgo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-riesgo", {
                        datosRiesgo: me.datosRiesgo,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarRiesgos();
                    me.modalNuevoRiesgo = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        verDetalleRiesgo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-riesgo", {
                        datosRiesgo: me.datosVerDetalleRiesgo,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarRiesgos();
                    me.modalVerDetalleRiesgo = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        calcularNrt() {
            let me = this;
            let gravedad = me.datosRiesgo.gravedad != '' ? parseFloat(me.datosRiesgo.gravedad) : 0;
            let detectabilidad = me.datosRiesgo.detectabilidad != '' ? parseFloat(me.datosRiesgo.detectabilidad) : 0;
            let ocurrencia = me.datosRiesgo.ocurrencia != '' ? parseFloat(me.datosRiesgo.ocurrencia) : 0;
            let result = gravedad * detectabilidad * ocurrencia;
            me.datosRiesgo.nivelTolerancia = result.toFixed(2);
        },
        calcularNrtActualizar() {
            let me = this;
            let gravedad = me.datosVerDetalleRiesgo.gravedad != '' ? parseFloat(me.datosVerDetalleRiesgo.gravedad) : 0;
            let detectabilidad = me.datosVerDetalleRiesgo.detectabilidad != '' ? parseFloat(me.datosVerDetalleRiesgo.detectabilidad) : 0;
            let ocurrencia = me.datosVerDetalleRiesgo.ocurrencia != '' ? parseFloat(me.datosVerDetalleRiesgo.ocurrencia) : 0;
            let result = gravedad * detectabilidad * ocurrencia;
            me.datosVerDetalleRiesgo.nivelTolerancia = result.toFixed(2);
        },
        abrirModalVerDetalleRiesgo(param) {
            let me = this;

            me.datosVerDetalleRiesgo.idRiesgo = param.item.idRiesgo,
                me.datosVerDetalleRiesgo.nombre = param.item.nombre,
                me.datosVerDetalleRiesgo.idProceso = param.item.idProceso,
                me.datosVerDetalleRiesgo.actividad = param.item.actividad,
                me.datosVerDetalleRiesgo.tipo = param.item.tipo,
                me.datosVerDetalleRiesgo.nivel = param.item.nivel,
                me.datosVerDetalleRiesgo.gravedad = param.item.gravedad,
                me.datosVerDetalleRiesgo.detectabilidad = param.item.detectabilidad,
                me.datosVerDetalleRiesgo.ocurrencia = param.item.ocurrencia,
                me.datosVerDetalleRiesgo.nivelTolerancia = param.item.nivelTolerancia,
                me.datosVerDetalleRiesgo.descripcion = param.item.descripcion,
                me.datosVerDetalleRiesgo.modoFallo = param.item.modoFallo,
                me.datosVerDetalleRiesgo.efecto = param.item.efecto,
                me.datosVerDetalleRiesgo.causa = param.item.causa,
                me.datosVerDetalleRiesgo.controlActual = param.item.controlActual

            me.modalVerDetalleRiesgo = true;
        },
        resetModalNuevoRiesgo() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosRiesgo.nombre = '',
                this.datosRiesgo.idProceso = null,
                this.datosRiesgo.actividad = '',
                this.datosRiesgo.tipo = null,
                this.datosRiesgo.nivel = null,
                this.datosRiesgo.gravedad = '',
                this.datosRiesgo.detectabilidad = '',
                this.datosRiesgo.ocurrencia = '',
                this.datosRiesgo.nivelTolerancia = '',
                this.datosRiesgo.descripcion = '',
                this.datosRiesgo.modoFallo = '',
                this.datosRiesgo.efecto = '',
                this.datosRiesgo.causa = '',
                this.datosRiesgo.controlActual = ''
        },
        resetModalVerDetalleRiesgo() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        eliminarRiesgo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el riesgo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-riesgo", {
                            idRiesgo: param.item.idRiesgo,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarRiesgos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalNuevoRiesgo: function (val) {
            if (val == false) {
                this.resetModalNuevoRiesgo();
            }
        },
        modalVerDetalleRiesgo: function (val) {
            if (val == false) {
                this.resetModalVerDetalleRiesgo();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarRiesgos();
            this.listarProcesos();
        }

    }

}
</script>
